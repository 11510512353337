<template>
  <div class="MessageCenters">
    <Header :Read="Read"/>
    <div class="contentssss">
      <div class="contentWrap">
        <div class="tab">
          <div class="tab_item" @click="select(1)" :class="{current: current === 1}">面试通知</div>
          <div class="tab_item" @click="select(2)" :class="{current: current === 2}">系统消息
            <!--            <span class="circular">面试通知</span>-->
          </div>
        </div>
        <div v-if="current === 1" class="InterviewList">
          <div :v-if="InterviewList.length>0" class="item" v-for="(item, index) in InterviewList" :key="index">
            <div class="logo">
              <img :src="item.company?.logo?.url">
            </div>
            <div class="right">
              <div class="tops">
                <div class="name">{{ item.title }}.{{ item.company?.name }}</div>
                <div class="date">{{ item.createTimeFormat }}</div>
              </div>
              <div class="desc">{{ item?.interview?.interviewData[0]?.content }}</div>
              <div class="info"  @click="openDetails(item)" style="cursor: pointer">
                <div class="leftDetails">
                  <div class="item">
                    <div class="time">面试时间：{{ item?.interview?.interviewData[0]?.time }}</div>
                    <div v-if="item.interview?.interviewData[0].interviewType==='SCENE'" class="adress">
                      面试地点：{{ item?.interview?.interviewData[0]?.interviewAddress }}
                    </div>
                    <div v-else class="adress">线上面试链接：{{ item?.interview?.interviewData[0]?.url }}</div>
                  </div>
                  <div class="item">
                    <div class="time">联系人：{{ item?.interview?.interviewData[0]?.contact }}</div>
                    <div class="adress">联系方式：{{ item?.interview?.interviewData[0]?.contactPhone }}</div>
                  </div>
                </div>

                <div class="btns" v-if="item.isShowInterview">
                  <div
                      style="border-radius: 0 8px 0 8px;padding: 4px 10px 4px 10px;background: #60C40926;color: #60C409;font-size: 14px"
                      v-if="item.jobMember?.viewStatus === 'VIEWACCEPT'">已接受
                  </div>
                  <div
                      style="border-radius: 0 8px 0 8px;padding: 4px 10px 4px 10px;background: #FF4D4F26;color: #FF4D4F;font-size: 14px"
                      v-if="item.jobMember?.viewStatus === 'VIEWREFUSE'">已取消
                  </div>
                  <div class="box1"
                       v-if="item.jobMember?.viewStatus === 'VIEWDEFAULT' || item.jobMember?.viewStatus ==='VIEWACCEPT'"
                       @click.stop="interviewConfirm(item, 'VIEWREFUSE')">
                    <span v-if="item.jobMember?.viewStatus === 'VIEWDEFAULT'">拒绝</span>
                    <span v-if="item.jobMember?.viewStatus === 'VIEWACCEPT'">取消</span>
                  </div>
                  <div class="box2" v-if="item.jobMember?.viewStatus === 'VIEWDEFAULT'"
                       @click.stop="interviewConfirm(item, 'VIEWACCEPT')">接受
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="InterviewList.length===0">
            <el-empty description="暂无面试消息"></el-empty>
          </div>
        </div>
        <div class="system" v-if="current === 2">
          <div :v-if="InterviewList.length>0" class="item" v-for="(item, index) in InterviewList" :key="index">
            <div class="top">
              <div class="name">
<!--                <div class="circular" style="margin-left: 0;margin-right: 5px"></div>-->
                {{ item.content }}
                <div class="time">{{ item.createTimeFormat }}</div>
              </div>
            </div>
            <!--            <div class="message">崔宸泷 ，你好！华北试用-招聘Light版 邀请你参加在线测评, <span>开始测评</span></div>-->
          </div>
          <div v-if="InterviewList.length===0">
            <el-empty description="暂无系统消息"></el-empty>
          </div>
        </div>
        <div style="line-height: 20px; margin-bottom: 20px;"></div>
        <div class="pagination">
          <el-pagination
              v-if="totalCount>0"
              background
              layout="prev, pager, next, total"
              :total="totalCount"
              :page-size="6"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <Footer/>
    <floatRight/>
    <el-dialog
        title="面试详情"
        :visible.sync="dialogVisible"
        width="484px"
        :before-close="handleClose">
      <div class="InterviewDetalis" style="margin-top: 10px">
        <div class="companyInfo">
          <img :src="jobDetails?.company?.logo.url">
          <div class="right">
            <div class="name">{{ jobDetails?.company?.name }}</div>
<!--            <div class="desc">{{ jobDetails?.interview?.createUserNickname }}</div>-->
          </div>
        </div>
        <div class="table">
          <div class="item">
            <div class="title">面试时间</div>
            <div class="value">{{ jobDetails?.jobMember?.interviewData[0].time }}</div>
          </div>
          <div class="item">
            <div class="title">面试官</div>
            <div class="value" style="margin-right: 5px" v-for="(item, index) in jobDetails?.interview?.interviewerComment" :key="index">{{ item }}</div>
          </div>
          <div class="item">
            <div class="title">面试内容</div>
            <div class="value">{{ jobDetails?.jobMember?.interviewData?.[0].content }}</div>
          </div>

          <div class="item" v-if="jobDetails?.jobMember?.interviewData?.[0].url">
            <div class="title">面试链接</div>
            <div class="value">{{ jobDetails?.jobMember?.interviewData?.[0].url }}</div>
          </div>
          <div class="item" v-else>
            <div class="title">面试地点</div>
            <div class="value">{{ jobDetails?.jobMember?.interviewData?.[0].interviewAddress }}</div>
          </div>
          <div class="item">
            <div class="title">联系电话</div>
            <div class="value">{{ jobDetails?.jobMember?.interviewData?.[0].contactPhone }}</div>
          </div>
        </div>
        <div class="btns">
          <div class="box1" @click="dialogVisible = false">取消</div>
          <div class="box2" @click="jieshou(jobDetails, 'VIEWREFUSE')">接受</div>

        </div>
      </div>
    </el-dialog>
    <el-dialog
        :title="title"
        :visible.sync="dialogVisible2"
        width="484px"
        :before-close="handleClose2">
      <div class="InterviewDetalis" style="margin-top: 10px">
        <div class="jujueLiyou">
          <div class="key">拒绝理由</div>
          <el-select v-model="value" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.label"
                :label="item.label"
                :value="item.label">
            </el-option>
          </el-select>
        </div>
        <el-input
            type="textarea"
            :rows="6"
            placeholder="请输入拒绝原因"
            v-model="textarea">
        </el-input>
        <div class="btns">
          <div class="box1" @click="dialogVisible2 = false">取消</div>
          <div class="box2" @click="jujue">提交</div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
        title="面试详情"
        :visible.sync="dialogVisible3"
        width="484px"
        :before-close="handleClose3">
      <div class="InterviewDetalis" style="margin-top: 10px">
        <div class="companyInfo">
          <img :src="jobDetails?.company?.logo.url">
          <div class="right">
            <div class="name">{{ jobDetails?.company?.name }}</div>
<!--            <div class="desc">{{ jobDetails?.interview?.createUserNickname }}</div>-->
          </div>
        </div>
        <div class="table">
          <div class="item">
            <div class="title">面试时间</div>
            <div class="value">{{ jobDetails?.jobMember?.interviewData[0].time }}</div>
          </div>
          <div class="item">
            <div class="title">面试官</div>
            <div class="value" style="margin-right: 5px" v-for="(item, index) in jobDetails?.interview?.interviewerComment" :key="index">{{ item }}</div>
          </div>
          <div class="item">
            <div class="title">面试内容</div>
            <el-tooltip :content="jobDetails?.jobMember?.interviewData?.[0].content" placement="top" effect="light">
              <div class="value" style="cursor: pointer">{{ jobDetails?.jobMember?.interviewData?.[0].content }}</div>
            </el-tooltip>
          </div>

          <div class="item" v-if="jobDetails?.jobMember?.interviewData?.[0].url">
            <div class="title">面试链接</div>
            <div class="value">{{ jobDetails?.jobMember?.interviewData?.[0].url }}</div>
          </div>
          <div class="item" v-else>
            <div class="title">面试地点</div>
            <div class="value">{{ jobDetails?.jobMember?.interviewData?.[0].interviewAddress }}</div>
          </div>
          <div class="item">
            <div class="title">联系电话</div>
            <div class="value">{{ jobDetails?.jobMember?.interviewData?.[0].contactPhone }}</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Header from "@/components/headers/index.vue";
import Footer from "@/components/footer/index.vue";
import floatRight from "@/components/floatRight/vue.vue";
import {messagepage, readAll} from "@/api/currency";
import {interviewConfirm} from "@/api/position";

export default {
  components: {floatRight, Footer, Header},
  data() {
    return {
      options: [
        {
          label: '时间冲突'
        },
        {
          label: '工作内容不合适'
        },
        {
          label: '暂不寻找工作'
        },
        {
          label: '已找到工作'
        },
        {
          label: '职位地点不合适'
        },
        {
          label: '薪资达不到预期'
        },
        {
          label: '其他'
        },
      ],
      value: '',
      Read: false,
      textarea: '',
      jobDetails: {},
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      current: 1,
      InterviewList: [],
      pagination: {
        number: 1,
        limit: 6
      },
      totalCount: 0,
      viewStatus: '',
      title: ''
    }
  },
  watch: {
    pagination: {
      handler() {
        if (this.current === 1) {
          this.messagepage()
        }
      },
      deep: true
    },
  },
  created() {
    this.messagepage()
    this.readAll()
  },
  methods: {
    async readAll() {
      await readAll()
      this.Read = true
    },
    handleClose() {
      this.dialogVisible = false
    },
    handleClose2() {
      this.dialogVisible2 = false
    },
    handleClose3() {
      this.dialogVisible3 = false
    },
    async messagepage() {
      this.InterviewList = []
      let type = 'INTERVIEW'
      if (this.current === 2) {
        type = 'DEFAULTTYPE'
      }
      const res = await messagepage({type: type}, this.pagination)
      this.InterviewList = res.data
      this.totalCount = res.totalCount
    },
    select(el) {
      this.current = el
      this.messagepage()
    },
    openDetails(jobDetails) {
      this.jobDetails = jobDetails
      this.dialogVisible3 = true
    },
    async interviewConfirm(jobDetails, viewStatus) {
      console.log(jobDetails, viewStatus)
      if (jobDetails.jobMember?.viewStatus === 'VIEWDEFAULT') {
        this.title = '拒绝原因'
      }
      if (jobDetails.jobMember?.viewStatus === 'VIEWACCEPT') {
        this.title = '取消原因'
      }
      this.jobDetails = jobDetails
      this.viewStatus = viewStatus
      if (viewStatus === 'VIEWACCEPT') {
        this.dialogVisible = true
      } else {
        this.dialogVisible2 = true
      }
    },
    async jieshou() {
      let params = {}
      console.log(this.jobDetails.jobMember.round)
      params.viewStatus = this.viewStatus
      params.round = this.jobDetails.jobMember.round
      params.vid = this.jobDetails.interview.id
      const {data} = await interviewConfirm(this.jobDetails.jobMember.dv, params)
      console.log(data)
      this.$message.success('操作成功')
      // 刷新界面
      await this.messagepage()
      this.dialogVisible = false

    },
    async jujue() {
      let params = {}
      params.viewStatus = this.viewStatus
      params.round = this.jobDetails.jobMember.round
      params.cancelNote = this.textarea
      params.vid = this.jobDetails.interview.id
      params.memberCancelNote = this.value
      const {data} = await interviewConfirm(this.jobDetails.jobMember.dv, params)
      console.log(data)
      this.$message.success('操作成功')
      // 刷新界面
      await this.messagepage()
      this.dialogVisible2 = false

    },
    handleCurrentChange(el) {
      this.pagination.number = el
    },
  }
}
</script>


<style  lang="scss">
.MessageCenters {
  background: #F5F5F6;
  width: 100%;
  overflow: hidden;
  min-height: 100vh;

  .el-textarea__inner {
    background: #F3F3F3;
  }

  .InterviewDetalis {
    padding-bottom: 20px;
    .jujueLiyou{
      margin-bottom: 15px;
      height: 58px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #D7D7D7;
      .el-select .el-input__inner{
        text-align: right;
        border: 1px solid #fff !important;
      }
      .key{
        color: #333333;
        font-size: 16px;
        font-weight: 600;
      }
    }

    .companyInfo {
      display: flex;
      gap: 16px;
      align-items: center;

      img {
        width: 48px;
        height: 48px;
        border-radius: 7px;
      }

      .right {
        display: flex;
        flex-direction: column;
        gap: 6px;

        .name {
          font-weight: 500;
          font-size: 16px;
        }

        .desc {
          color: #666666;
          font-size: 14px;
        }
      }
    }

    .btns {
      margin-top: 20px;
      display: flex;
      align-items: center;
      gap: 10px;

      .box1 {
        border: 1px solid #ADADAD;
        cursor: pointer;
        width: 214px;
        height: 44px;
        border-radius: 100px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333333;
        font-size: 16px;
        font-weight: 500;
      }

      .box2 {
        cursor: pointer;
        width: 214px;
        height: 44px;
        border-radius: 100px;
        background: #3A76FF;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
      }
    }

    .table {
      display: flex;
      flex-direction: column;

      .item {
        border-bottom: 1px solid #E3E4E7;
        height: 64px;
        display: flex;
        align-items: center;

        .title {
          width: 178px;
          color: #808080;
          font-size: 16px;
          font-weight: 400;
        }

        .value {
          color: #333333;
          font-size: 16px;
          font-weight: 400;
          max-height: 43px;
          overflow:hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }

        .btn {
          padding: 6px 8px;
          background: rgba($color: #000000, $alpha: 0.04);
          border-radius: 2px;
          color: rgba($color: #000000, $alpha: 0.45);
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  .contentssss {
    background: #fff;
    border-radius: 8px;
    width: 1000px;
    min-height: calc(100vh - 160px);
    margin: 72px auto 24px auto;
    position: relative;
    z-index: 9;
    .contentWrap {

      padding: 8px 22px 22px 22px;

      .circular {
        margin-left: 4px;
        width: 6px;
        height: 6px;
        background: #FF4D4F;
        border-radius: 50%;
      }
      .system {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .item {
          display: flex;
          flex-direction: column;
          gap: 5px;
          border-bottom: 1px solid #F1F1F1;

          .top {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .name {
              display: flex;
              //align-items: flex-end;
              flex-direction: column;
              width: 100%;
              color: #333333;
              font-size: 16px;
              font-weight: 500;
            }

            .time {
              display: flex;
              justify-content: flex-end;
              color: #808080;
              font-size: 14px;
              font-weight: 400;
              margin-bottom: 10px;
            }
          }

          .message {
            color: #666666;
            font-size: 14px;
            font-weight: 400;

            span {
              color: #3A76FF;
              cursor: pointer;
            }
          }
        }
      }

      .InterviewList {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .item {
          display: flex;
          gap: 16px;
          justify-content: space-between;

          .right {
            flex: 1;

            .info {
              padding: 12px 16px;
              background: #F7F8FA;
              border-radius: 8px;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .leftDetails {
                display: flex;
                flex-direction: column;
                gap: 14px;

                .item {
                  display: flex;
                  align-items: center;
                }
              }

              .btns {
                display: flex;
                align-items: center;
                gap: 8px;

                .box1 {
                  cursor: pointer;
                  border: 1px solid #ADADAD;
                  width: 60px;
                  height: 34px;
                  border-radius: 4px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: #555555;
                  font-size: 14px;
                  font-weight: 400;
                }

                .box2 {
                  cursor: pointer;
                  background: #3A76FF;
                  width: 60px;
                  height: 34px;
                  border-radius: 4px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: #fff;
                  font-size: 14px;
                  font-weight: 400;
                }
              }

              .time {
                width: 249px;
                color: #555555;
                font-size: 14px;
                font-weight: 400;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
              }

              .adress {
                width: 340px;
                color: #555555;
                font-size: 14px;
                font-weight: 400;
                flex: 1;
                margin-left: 40px;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
              }
            }

            .desc {
              max-width: 865px;
              overflow: hidden;
              margin: 12px 0;
              color: #666666;
              font-size: 14px;
              font-weight: 400;
            }

            .tops {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .name {
                color: #333333;
                font-size: 16px;
                font-weight: 500;
              }

              .date {
                color: #808080;
                font-size: 14px;
                font-weight: 400;
              }
            }
          }

          .logo {
            width: 48px;
            height: 48px;

            img {
              border-radius: 7px;
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .tab {
        display: flex;
        align-items: center;
        gap: 16px;

        .current {
          border-bottom: 3px solid #3A76FF;
          color: #3A76FF !important;
        }

        .tab_item {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 114px;
          height: 46px;
          color: #333333;
          font-size: 18px;
          font-weight: 400;

        }
      }
    }
  }
}
</style>
